import { MetaInfo } from 'vue-meta';
import {
  MetaPropertyCharset,
  MetaPropertyEquiv,
  MetaPropertyMicrodata,
  MetaPropertyName,
  MetaPropertyProperty
} from 'vue-meta/types/vue-meta';

const defaultTitle = 'Kakadoo - Online půjčovna dodávek';
const defaultDescription =
  'Online půjčovna dodávek Praha, Plzeň, Zlín. Vyberte si termín a dodávku, registrujte se, zaplaťte a vyrazte! Všechno vyřídíte on-line 24/7. Lokality Holešovice, Letňany, Černý most, Strahov, Zličín, Vršovice a Hostivař.';

type Metas = (
  | MetaPropertyCharset
  | MetaPropertyEquiv
  | MetaPropertyName
  | MetaPropertyMicrodata
  | MetaPropertyProperty
)[];

const defaultMetas: Metas = [
  {
    property: 'fb:app_id',
    content: '991022708053572'
  },
  {
    property: 'twitter:image',
    content: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/social.jpg'
  },
  {
    property: 'og:image',
    content: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/social.jpg'
  }
];

// covers basic SEO attr's, see vue-meta docs for more options
// also adds default seo settings
export function metaInfo({
  title = defaultTitle,
  description = defaultDescription,
  index = true,
  metas,
  url,
  canonical,
  lang
}: {
  title?: string;
  description?: string;
  index?: boolean;
  metas?: Metas;
  url?: string;
  canonical?: string;
  lang?: string;
}): MetaInfo {
  return {
    title,
    htmlAttrs: {
      lang: lang ?? 'cs'
    },
    meta: [
      {
        name: 'robots',
        content: index ? 'index,follow' : 'noindex, nofollow'
      },
      {
        name: 'keywords',
        content:
          'půjčovna dodávek, praha, brno, plzeň, zlín, Holešovice, Letňany, Černý most, Strahov, Zličín, Vršovice, Hostivař'
      },
      ...defaultMetas,
      ...(title
        ? [
            {
              property: 'og:title',
              content: title
            },
            {
              property: 'twitter:title',
              content: title
            }
          ]
        : []),
      ...(description
        ? [
            {
              name: 'description',
              content: description
            },
            {
              property: 'og:description',
              content: description
            },
            {
              property: 'twitter:description',
              content: description
            }
          ]
        : []),
      ...(url
        ? [
            {
              property: 'og:url',
              content: url
            }
          ]
        : []),
      ...(metas || [])
    ],
    link: [
      ...(canonical || url
        ? [
            {
              rel: 'canonical',
              href: canonical || url
            }
          ]
        : [])
    ]
  };
}
